<template>
  <!-- 评标审议 -->
  <div>
    <back />
    <div class="supplierManagement" v-loading="loading">
      <div class="globle_border content">
        <!-- 下拉搜索 -->
        <div class="select">
          <!-- 选择状态 -->
          <el-select v-model="bidStatusVal" placeholder="选择状态" clearable @change="onCurrentPage">
            <el-option v-for="item in bidStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-input placeholder="请输入品牌" suffix-icon="el-icon-search" v-model="brand" :clearable="true"
            @change="onSearch"></el-input>
          <el-input placeholder="请输入条形码" suffix-icon="el-icon-search" v-model="barCode" :clearable="true"
            @change="onSearch"></el-input>
        </div>
        <!-- </div> -->
        <!-- </header> -->
        <!-- 表格 -->
        <div class="globle_table tableBox">
          <el-table max-height="540" :data="tableData">
            <el-table-column label="序号" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
                <!-- 这里可以获取到当前行的索引号 -->
              </template>
            </el-table-column>
            <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="250" align="center">
            </el-table-column>
            <el-table-column prop="specs" label="规格" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip min-width="80" align="center">
            </el-table-column>
            <el-table-column prop="brand" label="品牌" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="factoryName" label="厂家" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="bidPeriod" label="标期" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="tendersLabel" label="标号" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="createdTime" label="发布时间" show-overflow-tooltip min-width="120" align="center">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.createdTime ? scope.row.createdTime.slice(0, 10) : "-" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="tendersTime" label="开标时间" show-overflow-tooltip min-width="120" align="center">
            </el-table-column>
            <el-table-column prop="eligibleType" label="应标资格" show-overflow-tooltip min-width="80" align="center">
              <template slot-scope="scope">
                <div>
                  {{ getEligibleText(scope.row.eligibleType) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="biddingPrice" label="投标价格" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip min-width="80" align="center">
              <template slot-scope="scope">
                <div :style="{ color: getStatusColor(scope.row.status) }">
                  {{ getStatusText(scope.row.status) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" header-align="center" align="center" min-width="150" fixed="right">
              <template slot-scope="scope">
                <!-- 1待投标、2已投标、3待评标、4已评标 -->
                <div v-if="scope.row.status == 2">
                  <el-button type="primary" size="small" plain @click="handleCommand(scope.row)">修改</el-button>
                  <el-button type="info" size="small" plain @click="handleCancelBid(scope.row)">取消</el-button>
                </div>
                <el-button v-else type="primary" size="small" plain @click="handleCommand(scope.row)">
                  {{ scope.row.status == 1 ? "投标" : "查看" }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
          layout="prev, pager, next, jumper, sizes, total" :total="totalCount"></el-pagination>
      </div>

      <!-- 待投标已开标查看弹窗 -->
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" @close="handleCloseDialog">
        <el-form v-if="dialogTitle == '查看'" label-width="150px" label-position="right" :model="formLabelAlign">
          <el-row>
            <el-col :span="11">
              <el-form-item label="品牌">
                <el-input v-model="formLabelAlign.brand" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="商品名称">
                <el-input v-model="formLabelAlign.productName" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="规格">
                <el-input v-model="formLabelAlign.specs" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="入箱数">
                <el-input v-model="formLabelAlign.enterBox" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="厂家">
                <el-input v-model="formLabelAlign.factoryName" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="标期">
                <el-input v-model="formLabelAlign.bidPeriod" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="标号">
                <el-input v-model="formLabelAlign.tendersLabel" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="开标时间">
                <el-input v-model="formLabelAlign.tendersTime" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="formLabelAlign.status == null ? false : true">
            <el-col :span="11">
              <el-form-item label="应标资格类型">
                <el-input :value="formLabelAlign.eligibleType ? '平台资格' : '独立标资格'" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="投标价格">
                <el-input v-model="formLabelAlign.biddingPrice" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="formLabelAlign.status !== null">
            <el-col :span="11">
              <el-form-item label="中标状态">
                <!-- //状态://状态(1:待评标 ；2:已中标；3未中标 ；4：废标；null：未投标) -->
                <el-input :value="statusData[formLabelAlign.status]" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form v-else label-width="150px" label-position="right" :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-row>
            <el-col :span="10">
              <el-form-item label="品牌">
                <el-input v-model="ruleForm.brand" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="商品名称">
                <el-input v-model="ruleForm.productName" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="规格">
                <el-input v-model="ruleForm.specs" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="商品分类">
                <el-input v-model="ruleForm.categoryName" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="入箱数">
                <el-input v-model="ruleForm.enterBox" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="商品条形码">
                <el-input v-model="ruleForm.barCode" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="投标价格" prop="biddingPrice">
                <el-input placeholder="请输入投标价格" onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                  v-model="ruleForm.biddingPrice" @blur="inputChange"></el-input>
                <span style="color: grey; margin-left: 8px; font-size: 14px">
                  <i class="el-icon-warning" style="margin-right: 5px; color: #550000" @mouseover="showContent"
                    @mouseout="hideContent"></i>
                  <span style="position: relative" v-show="isContentVisible">只能携带两位小数</span>
                </span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" plain @click="handleCloseDialog">取 消</el-button>
          <el-button type="primary" size="small" v-if="dialogTitle == '投标' || dialogTitle == '修改'"
            @click="submitForm('ruleForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      statusData: {
        1: "待评标",
        2: "已中标",
        3: "未中标",
        4: "废标",
        null: "未投标",
      },
      loading: false,
      // 招标信息数据
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      // 投标状态 //状态1待投标、2已投标、3待评标、4已评标
      bidStatus: [
        {
          value: "1",
          label: "待投标",
        },
        {
          value: "2",
          label: "已投标",
        },
        {
          value: "3",
          label: "待评标",
        },
        {
          value: "4",
          label: "已评标",
        },
      ],
      //应标资格
      selectData: [
        {
          value: "1",
          label: "平台资格",
        },
        {
          value: "2",
          label: "独立标资格",
        },
      ],
      //选择标期--一级
      options: [],
      bidStatusVal: "", //投标状态
      bidEligibilityVal: "", //应标资格
      standardPeriodVal: "", //标期
      searchLabelTxt: "", //搜索标号
      productName: "", //商品名
      brand: "", //品牌
      barCode: "", //商品条形码
      eligibleId: "", //次数id
      // 弹窗数据
      dialogVisible: false, // 弹窗显隐
      dialogTitle: "", //弹窗标题
      // 查看信息
      formLabelAlign: {},
      // 投标可修改信息
      ruleForm: {},
      rules: {
        biddingPrice: [{ required: true, message: "请输入投标价格", trigger: "blur" }],
      },
      isContentVisible: false,
    }
  },
  beforeCreate() {
    localStorage.removeItem("bidList")
  },
  created() {
    this.searchLabelTxt = this.$route.query.label || ""
    this.getRequest()
    // this.getBidPeriod()
  },
  methods: {
    // 请求列表
    getRequest() {
      this.loading = true
      this.$axios
        .get(this.$api.sendSupplierTenders, {
          params: {
            barCode: this.barCode || null, // 条形码
            brand: this.brand || null, // 品牌
            status: this.bidStatusVal || null, //状态(状态1待投标、2已投标、3待评标、4已评标
            // bidPeriod: this.standardPeriodVal || null,//标期
            // eligibleType: this.bidEligibilityVal || null,
            tendersLabel: this.searchLabelTxt || null, //标号
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.totalCount = result.totalCount
            this.tableData = result.list
            // console.log('请求列表', result.list);
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 请求是否中标状态详情
    getRequestTendersView(row) {
      this.loading = true
      this.$axios
        .get(this.$api.sendSupplierTendersView, {
          params: {
            tendersProductId: row.tendersProductId,
          },
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.formLabelAlign = result
            // console.log('中标状态详情', result);
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 标期列表请求
    getBidPeriod() {
      this.$axios
        .get(this.$api.sendBidPeriodPage, {
          params: {
            // bidPeriod: this.standardPeriodVal,//标期
            page: this.currentPage,
            pageSize: 99999,
          },
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.options = result.list.map(item => {
              return {
                tendersId: item.tendersId, // 假设你想要保留tendersId
                value: item.bidPeriod,
                label: item.bidPeriod,
              }
            })
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 投标验证资格
    sendCheckEligible(row) {
      this.$axios
        .get(this.$api.sendCheckEligible, {
          params: {
            supplierId: this.$store.state.userInfo.supplierId, //供应商id
            tendersId: row.tendersId, //独立标传标书tendersId平台标传eligibleId
          },
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            // 资格状态 -1没资格；0审核中；1有资格；2投标次数；3已过期
            this.eligibleId = result.eligibleId
            // console.log('有资格时', result.eligibleId);
            // console.log('资格', result.eligibleStatus);
            if (result.eligibleStatus == -1) {
              const h = this.$createElement
              this.$confirm("提示", {
                title: "提示",
                message: h("div", [h("p", "您还没有该标应标资格！请前往申请应标资格,"), h("p", " 是否继续 ?")]),
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                center: true,
                type: "warning",
              })
                .then(() => {
                  this.$router.push({ name: "bidEligibility", query: { biddingStatus: row.eligibleType, tendersId: row.tendersId } })
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消申请应标资格!",
                  })
                })
            } else if (result.eligibleStatus == 0) {
              this.$alert("您的应标资格在审核中,请联系管理员审核！", "提示", {
                confirmButtonText: "确定",
                center: true,
                type: "warning",
              })
            } else if (result.eligibleStatus == 1) {
              //eligibleId需要传递投标接口
              this.dialogVisible = true
              this.ruleForm = row
            } else if (result.eligibleStatus == 2) {
              const h = this.$createElement
              this.$confirm("提示", {
                title: "提示",
                message: h("div", [h("p", "您的应标资格已超过投标次数！请前往申请应标资格,"), h("p", " 是否继续 ?")]),
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                center: true,
                type: "warning",
              })
                .then(() => {
                  this.$router.push({ name: "bidEligibility", query: { biddingStatus: row.eligibleType } })
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消申请应标资格!",
                  })
                })
            } else {
              const h = this.$createElement
              this.$confirm("提示", {
                title: "提示",
                message: h("div", [h("p", "您的应标资格已超过有效期！请前往申请应标资格,"), h("p", " 是否继续 ?")]),
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                center: true,
                type: "warning",
              })
                .then(() => {
                  this.$router.push({ name: "bidEligibility", query: { biddingStatus: row.eligibleType } })
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消申请应标资格!",
                  })
                })
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 级联选择搜索
    handleChange(val) {
      this.tableData = []
      this.standardPeriodVal = val
      // console.log('级联选择', val);
      this.getRequest()
    },
    // 标号搜索+清除路径上的参数
    labelSearch() {
      this.getRequest()
      if (!this.searchLabelTxt) {
        window.history.replaceState(null, null, this.gelDelURL())
      }
    },

    // 搜索商品名、品牌
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${ val } 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      this.tableData = []
      // console.log(`当前页: ${ val } `);
      this.currentPage = val
      this.getRequest()
    },
    // 关闭查看弹窗
    handleCloseDialog() {
      this.dialogVisible = false
      if (this.dialogTitle == "投标") {
        // 清空数据
        this.resetForm() //重置校验
      }
    },
    // 操作详情 1待投标、2已投标、3待评标、4已评标
    handleCommand(row) {
      // console.log('投标验证', row);
      // this.sendCheckEligible(row)
      localStorage.setItem("bidList", JSON.stringify(row))
      switch (row.status) {
        case "1":
          this.dialogTitle = "投标"
          // 投标信息验证资格
          this.sendCheckEligible(row)
          break
        case "3": // 查看已开标信息
          this.dialogTitle = "查看"
          this.dialogVisible = true
          // this.formLabelAlign = row//查看信息请求
          this.getRequestTendersView(row)
          break
        case "4": // 查看已开标信息
          this.dialogTitle = "查看"
          this.dialogVisible = true
          // this.formLabelAlign = row//查看信息请求
          this.getRequestTendersView(row)
          break
        default:
          // console.log('修改', row);
          this.dialogTitle = "修改"
          this.dialogVisible = true
          this.ruleForm = {
            id: row.id,
            productName: row.productName,
            brand: row.brand, //品牌
            specs: row.specs, //规格
            categoryName: row.categoryName, //分类
            barCode: row.barCode, //条形码
            biddingPrice: row.biddingPrice,
            enterBox: row.enterBox, //入箱数
          }
          break
      }
    },
    // 取消投标
    handleCancelBid(row) {
      // console.log('取消投标', row);
      const url = `${this.$api.sendDelBidding}/${row.id}`
      this.$confirm("确认要取消当前商品的投标, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
        type: "warning",
      })
        .then(() => {
          this.$axios
            .put(url)
            .then(res => {
              const { code } = res.data
              if (code == 100) {
                this.getRequest() //调取列表数据
                this.loading = false
                this.$message({
                  message: "恭喜你，取消成功!",
                  type: "success",
                })
                this.dialogVisible = false
              }
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          })
          this.loading = false
        })
    },
    // 投标校验价格输入(优化)
    async submitForm(formName) {
      try {
        // 校验表单
        await new Promise((resolve, reject) => {
          this.$refs[formName].validate(valid => {
            if (!valid) {
              this.$message.error("投标价格不能为空！")
              reject(new Error("表单校验失败")) // 拒绝Promise并传递错误
            } else {
              resolve() // 校验成功，解决Promise
            }
          })
        })
        // 准备要发送的数据
        const data = {
          eligibleId: this.eligibleId,
          depositAmount: this.ruleForm.depositAmount,
          tendersProductId: this.ruleForm.tendersProductId,
          tendersId: this.ruleForm.tendersId,
          productId: this.ruleForm.productId,
          biddingPrice: this.ruleForm.biddingPrice,
          startTime: this.ruleForm.effectiveStartTime,
          endTime: this.ruleForm.effectiveEndTime,
        }
        // console.log(data);
        // 根据状态决定是投标还是修改
        let response
        if (this.ruleForm.status == 1 && this.ruleForm.biddingPrice) {
          response = await this.$axios.post(this.$api.sendAddBidding, data)
        } else {
          // 在修改的情况下，可以添加必要的修改字段到data中，或者创建另一个对象
          response = await this.$axios.put(this.$api.sendUpdateBidding, {
            id: this.ruleForm.id,
            biddingPrice: this.ruleForm.biddingPrice,
          })
        }

        // 处理响应结果
        const { code } = response.data
        if (code == 100) {
          this.getRequest() // 调取列表数据
          this.loading = false
          this.$message({ message: code == 100 ? "恭喜你，投标成功!" : "恭喜你，修改成功!", type: "success" })
          this.dialogVisible = false
          this.resetForm() // 重置校验
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    // 检验重置
    resetForm() {
      this.ruleForm = {} //清空对象
      this.$refs.ruleForm.resetFields()
    },
    // 价格输入框失去焦点
    inputChange(e) {
      this.ruleForm.biddingPrice = e.target.value
      // console.log(this.ruleForm.biddingPrice);
    },
    // 清空路径上携带的参数
    gelDelURL() {
      return window.location.href.substring(0, window.location.href.indexOf("?"))
    },
    showContent() {
      this.isContentVisible = true // 显示隐藏内容
    },
    hideContent() {
      this.isContentVisible = false // 隐藏内容
    },
    // 状态文字颜色
    getStatusColor(status) {
      switch (status) {
        case "1":
          return "#069BBC" //待投标
        case "2":
          return "#E35B5A" //已投标
        default:
          return "" //已开标
      }
    },
    // 列表状态文字显示:1待投标、2已投标、3待评标、4已评标
    getStatusText(status) {
      switch (status) {
        case "1":
          return "待投标"
        case "2":
          return "已投标"
        case "3":
          return "待评标"
        default:
          return "已评标"
      }
    },
    getEligibleText(status) {
      switch (status) {
        case "1":
          return "平台资格"
        default:
          return "独立标资格"
      }
    },
  },
}
</script>

<style scoped lang="scss">
.supplierManagement {
  .content {
    padding: 0 20px 20px 20px; //上方有返回按钮，间隔太大 上方内边距更改为0

    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;
    }

    .select {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        margin-left: 10px;
        width: 200px;
        margin-bottom: 20px;
      }

    }
  }

  .eligiblePrice {
    color: #606266;
    padding: 0px 0px 0px 10px;
    background: #f2f2f2;
    border-radius: 10px;
  }

  .tableBox {
    overflow: auto;

    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: "";
      position: absolute;
      background-color: #ebeef5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }
  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}
</style>
